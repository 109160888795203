/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

interface Props {
  description?: string
  lang?: string
  title: string
  metaTitle?: string
  image: string
  meta: object[]
  canonicalUrls?: string[]
}

const SEO = ({
  title,
  metaTitle,
  description,
  image,
  lang,
  meta,
  canonicalUrls,
}: Props) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            defaultImage: image
            siteUrl
            author
          }
        }
      }
    `
  )

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
  } = site.siteMetadata

  const seo = {
    title: metaTitle || title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`, // s/b 1200x627
    url: `${siteUrl}${pathname}`,
    author,
  }
  const isHome = title.toLocaleLowerCase().indexOf("home") > -1
  const baseURL = siteUrl.endsWith("/") ? siteUrl : `${siteUrl}/`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title || title}
      titleTemplate={""}
      link={
        canonicalUrls &&
        canonicalUrls.map(c => {
          c = c.startsWith("/") ? c : `/${c}`
          c = c.endsWith("/") ? c : `${c}/`
          return {
            rel: "canonical",
            href: c ? `${siteUrl}${c}` : `${baseURL}`,
          }
        })
      }
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `image`,
          content: seo.image,
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: seo.author || ``,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
      ].concat(meta)}
    >
      {/* Add ShareASale Master Tag to Head of every page */}
      <script
        src="https://www.dwin1.com/58409.js"
        type="text/javascript"
        defer={true}
      ></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  title: ``,
  description: ``,
  image: null,
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
